import { HttpEvent, HttpRequest } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { environment } from '@root/environments/environment';
import { catchError, finalize, map, Observable, tap } from 'rxjs';

import { DevicesStore } from '../devices/devices.store';
import { AndroidDevice } from './device-details.types';
import { mapDeviceDetails } from './utils/map-device-details';

export interface DeviceAndroidUpdate
	extends Pick<AndroidDevice, 'name' | 'uninstallKey' | 'releaseChannel' | 'workOrder'> {
	group: string;
}

export interface DeviceSettingsUpdate {
	settingId: string;
	setting: {
		androidValue: string;
	};
	value: boolean;
}

export interface SettingUpdateResponse {
	value: boolean;
	settingId: string;
	setting: null;
	differentThanGroup: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class DeviceDetailsService {
	readonly #devicesStore = inject(DevicesStore);
	#apiService = inject(ApiService);

	isLoading = signal<boolean>(false);

	deleteDevice(deviceId: string) {
		return this.#apiService.delete(`/android/device/${deviceId}`);
	}

	getDevice(deviceId: string) {
		this.isLoading.set(true);

		return this.#apiService.get<AndroidDevice>(`/android/device/${deviceId}`).pipe(
			map(mapDeviceDetails),
			tap((data) => {
				this.#devicesStore.addDeviceDetail(data);
			}),
			catchError(() => {
				throw new Error('Error getting device details');
			}),
			finalize(() => this.isLoading.set(false)),
		);
	}

	getQrCode(deviceId: string): string {
		return `${environment.baseApi}/android/device/qrcode/${deviceId}`;
	}

	sendCommand(deviceId: string, command: number) {
		this.isLoading.set(true);

		return this.#apiService
			.get(`/android/device/sendcommand/${deviceId}?command=${command}`)
			.pipe(tap(() => this.isLoading.set(false)));
	}

	resetDeviceSetting(deviceId: string, settingId: string) {
		const options = { params: { settingId } };
		const link = `/android/device/settingsvalue/${deviceId}`;

		return this.#apiService.delete<SettingUpdateResponse>(link, options);
	}

	updateDevice(deviceId: string, data: DeviceAndroidUpdate) {
		return this.#apiService.put<void>(`/android/device/${deviceId}`, data);
	}

	updateDeviceRestriction(deviceId: string, data: DeviceSettingsUpdate) {
		return this.#apiService.put<SettingUpdateResponse>(
			`/android/device/settingsvalue/${deviceId}`,
			data,
		);
	}

	removeTimer(deviceId: string) {
		return this.#apiService.get(`/android/device/removetemporarypolicy/${deviceId}`);
	}

	uploadApk(deviceId: string, file: any): Observable<HttpEvent<any>> {
		const formData = new FormData();
		formData.append('apk', file);
		const req = new HttpRequest(
			'POST',
			`${environment.baseApi}/android/device/installapk/${deviceId}`,
			formData,
			{
				reportProgress: true,
			},
		);

		return this.#apiService.request(req);
	}
}
